



































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
@Component({
  components: {}
})
export default class Main extends Vue {
  collapsed: boolean = false;

  defaultOpenKeys: string[] = ['M0', 'M1', 'M2'];

  selectedKeys: string[] = [];
  created() {
    this.selectedKeys = [this.$route.fullPath];
  }

  onMenuClick(e: { key: string }) {
    let { key } = e;
    console.log(e);
    this.$router.push(key);
  }

  @Watch('$route')
  onRouteChange(to: Route) {
    this.selectedKeys = [to.fullPath];
  }
}
